<template>
  <div class="output-pills">
    <div
      v-for="(slide, idx) in loading ? [] : slides"
      :key="`output-pills-pill-${idx}`"
      class="output-pills-pill-wrapper"
    >
      <div
        class="output-pills-pill"
        :class="{
          dynamic: slide.dynamic,
          selected: selectedIdx === idx,
          inserted: slide.slide_type === 'template_block_slide',
          merged: slide.slide_type === 'merged_slide'
        }"
        @click="() => $emit('select', idx)"
      ></div>
    </div>
    <b-skeleton
      v-for="s in loading || !(slides && slides.length)
        ? [1, 2, 3, 4, 5, 6, 7]
        : []"
      :key="`output-pills-pill-${s}`"
      class="output-pills-pill"
    />
  </div>
</template>

<script>
export default {
  name: 'PresentationOutputPills',
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    selectedIdx: {
      type: Number,
      default: -1
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.output-pills {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &-pill {
    flex: 1;
    height: 0.5rem;
    border-radius: 999rem;
    background: rgba(#000, 0.32);
    opacity: 0.35;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    &.dynamic {
      background: $primary;
    }

    &.inserted {
      background: #8937df;
    }

    &.merged {
      background: #f29203;
    }

    &.selected {
      opacity: 1;
    }

    &-wrapper {
      display: contents;
    }
  }
}

::v-deep .b-skeleton {
  margin: 0;
  border-radius: 999rem;
  height: 0.5rem;

  &-item {
    height: 0.5rem;
    border-radius: 999rem;
  }
}
</style>
