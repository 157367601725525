<template>
  <b-modal :active="visible" :can-cancel="['escape', 'outside']" @close="close">
    <div class="output-insert">
      <div class="output-insert-header">
        <p class="output-insert-header-title">
          Insert slides from your portfolio
        </p>
        <Button icon="close" type="grey" size="xs" @click="close" />
      </div>
      <div class="output-insert-content">
        <div v-if="!step" class="insert-step">
          <div class="insert-step-header">
            From which selected offering, case or inspiration would you like to
            insert additional content
          </div>
          <div class="insert-step-select">
            <div
              v-for="option in insertOptions"
              :key="option.header"
              class="insert-step-select-section"
            >
              <div class="insert-step-select-section-header">
                {{ option.header }}
              </div>
              <div
                v-for="item in option.items"
                :key="item.uuid"
                class="insert-step-select-section-item"
                @click="() => selectORI(option.type, item)"
              >
                <Avatar
                  :user="{ image: oriImage(item) }"
                  fallback-icon="globe"
                  size="m"
                />
                <div class="insert-step-select-section-item-title">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="insert-step">
          <div v-if="resourcesLoading" class="insert-step-loading">
            <b-loading active :is-full-page="false" />
          </div>
          <Accordion
            v-else-if="resourcesList.length"
            :items="resourcesList"
            :multi-select="true"
            :opened="true"
            :bordered="true"
          >
            <template #header="{ item }">
              <div class="insert-step-resource-header">
                <DocumentTag :mimetype="item.integrationfile.mimetype" />
                {{ item.name }}
              </div>
            </template>
            <template #content="{ item }">
              <div class="insert-step-resource">
                <div
                  v-if="resourceSlidesLoading.includes(item.uuid)"
                  class="insert-step-resource-loading"
                >
                  <b-loading
                    :active="resourceSlidesLoading.includes(item.uuid)"
                    :is-full-page="false"
                  />
                </div>
                <div class="insert-step-resource-slides">
                  <div
                    v-for="slide in item.slides"
                    :key="slide.uuid"
                    class="insert-step-resource-slides-slide"
                    :class="{
                      selected: selected.some(s => s.uuid === slide.uuid)
                    }"
                    @click="() => selectSlide(slide)"
                  >
                    <img
                      :src="slide.preview"
                      alt=""
                      class="insert-step-resource-slides-slide-img"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Accordion>
          <div v-else class="insert-step-empty">
            <img
              src="@/assets/icons/slides.svg"
              alt=""
              class="insert-step-empty-img"
            />
            No content found with slides that can be inserted for this
            {{ typeName }}.
          </div>
        </div>
      </div>
      <div v-if="step" class="output-insert-footer">
        <Button
          :text="step ? 'Back' : 'Cancel'"
          type="white"
          :disabled="insertLoading"
          @click="back"
        />
        <Button
          :text="`${step ? 'Insert' : 'Select'} slides`"
          :disabled="step ? !selected.length : !ori"
          :loading="insertLoading"
          @click="submit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'
import Accordion from '@c/library/Accordion.vue'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'

import { addMeetingOutputSlides } from '@/services/meetingService'
import { downloadableMimetypes } from '@c/mimetypes'
import { mapActions } from 'vuex'

export default {
  name: 'PresentationOutputInsert',
  components: {
    Button,
    Avatar,
    Accordion,
    DocumentTag,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    },
    meeting: {
      type: Object,
      required: true
    },
    output: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    insertLoading: false,
    ori: undefined,
    type: '',
    resources: [],
    resourcesLoading: false,
    resourceSlidesLoading: [],
    selected: [],
    step: 0
  }),
  computed: {
    offerings() {
      return this.meeting.offerings || []
    },
    references() {
      return this.meeting.references || []
    },
    inspirations() {
      return this.meeting.inspirations || []
    },
    insertOptions() {
      return [
        ...(this.offerings.length
          ? [
              {
                header: 'Offerings',
                items: this.offerings,
                type: 'offering'
              }
            ]
          : []),
        ...(this.references.length
          ? [
              {
                header: 'Cases',
                items: this.references,
                type: 'reference'
              }
            ]
          : []),
        ...(this.inspirations.length
          ? [
              {
                header: 'Inspirational content',
                items: this.inspirations,
                type: 'inspiration'
              }
            ]
          : [])
      ]
    },
    resourcesList() {
      return Object.values(this.resources)
    },
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    }
  },
  methods: {
    ...mapActions([
      'getOfferingInfo',
      'getReferenceInfo',
      'getInspirationInfo',
      'getSubResourcePage'
    ]),
    close() {
      this.$emit('close')
    },
    back() {
      if (this.step) this.step -= 1
      else this.close()
    },
    async selectORI(type, ori) {
      this.ori = ori
      this.type = type
      this.submit()
      await this.loadResources()
      Object.values(this.resources).forEach(this.loadResourceSlides)
    },
    async submit() {
      if (!this.step) {
        this.step = 1
        return
      }
      this.insertLoading = true
      try {
        const res = await addMeetingOutputSlides({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.$route.params.meeting_id,
          output_id: this.output.uuid,
          slide_index: this.index,
          subresource_ids: this.selected.map(s => s.uuid)
        })
        this.$emit('insert', res)
      } finally {
        this.insertLoading = false
      }
    },
    async loadResources() {
      try {
        this.resourcesLoading = true
        const infoFunction = {
          offering: this.getOfferingInfo,
          reference: this.getReferenceInfo,
          inspiration: this.getInspirationInfo
        }[this.type]
        const ori = await infoFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.ori.uuid
        })
        this.resources = ori.resources.reduce(
          (acc, curr) => ({
            ...acc,
            ...(downloadableMimetypes.includes(curr.integrationfile.mimetype)
              ? {
                  [curr.uuid]: {
                    ...curr,
                    name: curr.title,
                    id: curr.uuid,
                    slides: []
                  }
                }
              : {})
          }),
          {}
        )
      } catch (e) {
        this.$console.debug('Error loading resources', e)
        this.$toast.error(e, 'loading content')
      } finally {
        this.resourcesLoading = false
      }
    },
    async loadResourceSlides(resource) {
      try {
        this.resourceSlidesLoading = [
          ...this.resourceSlidesLoading,
          resource.uuid
        ]
        const slides = await this.getSubResourcePage({
          workspace_id: this.$route.params.workspace_id,
          resource_id: resource.uuid,
          params: {
            page_size: 999,
            ...(this.$route.query.act_as
              ? { act_as: this.$route.query.act_as }
              : {})
          }
        })
        this.resources[resource.uuid].slides = slides
      } catch (e) {
        this.$console.debug('Error getting subresource page', e)
        this.$toast.error(e, 'getting slides')
      } finally {
        this.resourceSlidesLoading = this.resourceSlidesLoading.filter(
          uuid => uuid !== resource.uuid
        )
      }
    },
    selectSlide(slide) {
      this.selected = this.selected.includes(slide)
        ? this.selected.filter(s => s !== slide)
        : [...this.selected, slide]
    },
    oriImage(ori) {
      return (
        ori.image ||
        ori.account?.organisation?.icon ||
        ori.account?.organisation?.icon ||
        ''
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.output-insert {
  background: white;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);
  max-height: calc(100vh - 7rem);
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 4rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  &-content {
    flex: 1;
    overflow: auto;
    padding: 1.5rem;
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
    gap: 0.5rem;
  }
}

.insert-step {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding-bottom: 0.5rem;

  &-header {
    font-weight: 700;
  }

  &-select {
    display: flex;
    flex-flow: column nowrap;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    background: #f1f2f3;

    &-section {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(#000, 0.08);
      }

      &-header {
        padding: 0.5rem 1rem;
        font-weight: 600;
        border-bottom: 1px solid rgba(#000, 0.08);
      }

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.75rem;
        padding: 1rem 1.5rem;
        cursor: pointer;

        &:hover {
          background: rgba(#000, 0.04);
        }

        &-title {
          font-size: 1.1rem;
          font-weight: 600;
        }
      }
    }
  }

  &-loading {
    min-height: 5rem;
    position: relative;
  }

  &-empty {
    color: #60666b;
    font-weight: 600;
    font-size: 1.1rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
  }

  &-resource {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 0.5rem;

    &-loading {
      min-height: 5rem;
      position: relative;
    }

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;
    }

    &-slides {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      gap: 1rem;

      &-slide {
        cursor: pointer;
        border-radius: 4px;
        padding: 0.25rem;
        border: 2px solid transparent;
        max-height: 20rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: fit-content;

        &-img {
          object-fit: cover;
          max-height: 100%;
        }

        &.selected {
          border: 2px solid $primary;
        }

        &:hover {
          & > .insert-step-resource-slides-slide-img {
            box-shadow: 0 2px 4px rgba(#000, 0.16);
          }
        }
      }
    }
  }
}
</style>
