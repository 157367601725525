var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible, "can-cancel": ["escape", "outside"] },
      on: { close: _vm.close }
    },
    [
      _c("div", { staticClass: "output-insert" }, [
        _c(
          "div",
          { staticClass: "output-insert-header" },
          [
            _c("p", { staticClass: "output-insert-header-title" }, [
              _vm._v(" Insert slides from your portfolio ")
            ]),
            _c("Button", {
              attrs: { icon: "close", type: "grey", size: "xs" },
              on: { click: _vm.close }
            })
          ],
          1
        ),
        _c("div", { staticClass: "output-insert-content" }, [
          !_vm.step
            ? _c("div", { staticClass: "insert-step" }, [
                _c("div", { staticClass: "insert-step-header" }, [
                  _vm._v(
                    " From which selected offering, case or inspiration would you like to insert additional content "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "insert-step-select" },
                  _vm._l(_vm.insertOptions, function(option) {
                    return _c(
                      "div",
                      {
                        key: option.header,
                        staticClass: "insert-step-select-section"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "insert-step-select-section-header" },
                          [_vm._v(" " + _vm._s(option.header) + " ")]
                        ),
                        _vm._l(option.items, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.uuid,
                              staticClass: "insert-step-select-section-item",
                              on: {
                                click: function() {
                                  return _vm.selectORI(option.type, item)
                                }
                              }
                            },
                            [
                              _c("Avatar", {
                                attrs: {
                                  user: { image: _vm.oriImage(item) },
                                  "fallback-icon": "globe",
                                  size: "m"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "insert-step-select-section-item-title"
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              ])
            : _c(
                "div",
                { staticClass: "insert-step" },
                [
                  _vm.resourcesLoading
                    ? _c(
                        "div",
                        { staticClass: "insert-step-loading" },
                        [
                          _c("b-loading", {
                            attrs: { active: "", "is-full-page": false }
                          })
                        ],
                        1
                      )
                    : _vm.resourcesList.length
                    ? _c("Accordion", {
                        attrs: {
                          items: _vm.resourcesList,
                          "multi-select": true,
                          opened: true,
                          bordered: true
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "insert-step-resource-header"
                                  },
                                  [
                                    _c("DocumentTag", {
                                      attrs: {
                                        mimetype: item.integrationfile.mimetype
                                      }
                                    }),
                                    _vm._v(" " + _vm._s(item.name) + " ")
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "content",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "div",
                                  { staticClass: "insert-step-resource" },
                                  [
                                    _vm.resourceSlidesLoading.includes(
                                      item.uuid
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "insert-step-resource-loading"
                                          },
                                          [
                                            _c("b-loading", {
                                              attrs: {
                                                active: _vm.resourceSlidesLoading.includes(
                                                  item.uuid
                                                ),
                                                "is-full-page": false
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "insert-step-resource-slides"
                                      },
                                      _vm._l(item.slides, function(slide) {
                                        return _c(
                                          "div",
                                          {
                                            key: slide.uuid,
                                            staticClass:
                                              "insert-step-resource-slides-slide",
                                            class: {
                                              selected: _vm.selected.some(
                                                function(s) {
                                                  return s.uuid === slide.uuid
                                                }
                                              )
                                            },
                                            on: {
                                              click: function() {
                                                return _vm.selectSlide(slide)
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "insert-step-resource-slides-slide-img",
                                              attrs: {
                                                src: slide.preview,
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    : _c("div", { staticClass: "insert-step-empty" }, [
                        _c("img", {
                          staticClass: "insert-step-empty-img",
                          attrs: {
                            src: require("@/assets/icons/slides.svg"),
                            alt: ""
                          }
                        }),
                        _vm._v(
                          " No content found with slides that can be inserted for this " +
                            _vm._s(_vm.typeName) +
                            ". "
                        )
                      ])
                ],
                1
              )
        ]),
        _vm.step
          ? _c(
              "div",
              { staticClass: "output-insert-footer" },
              [
                _c("Button", {
                  attrs: {
                    text: _vm.step ? "Back" : "Cancel",
                    type: "white",
                    disabled: _vm.insertLoading
                  },
                  on: { click: _vm.back }
                }),
                _c("Button", {
                  attrs: {
                    text: (_vm.step ? "Insert" : "Select") + " slides",
                    disabled: _vm.step ? !_vm.selected.length : !_vm.ori,
                    loading: _vm.insertLoading
                  },
                  on: { click: _vm.submit }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }