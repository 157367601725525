<template>
  <div class="presentation-preview">
    <div class="presentation-preview-slide">
      <div
        v-if="showContent"
        class="presentation-preview-slide-preview"
        :class="{ 'full-height': !selectedSlideDynamic || hideRegenerate }"
      >
        <div class="presentation-preview-slide-preview-img-wrapper">
          <img
            :src="selectedSlide.preview"
            alt=""
            class="presentation-preview-slide-preview-img"
          />
        </div>
      </div>
      <b-skeleton v-else />
      <div
        class="presentation-preview-slide-prompt"
        :class="{ visible: selectedSlideDynamic && !hideRegenerate }"
      >
        <TextInput
          v-model="prompt"
          :placeholder="promptPlaceholder"
          :max-length="200"
          :disabled="!showContent"
          class="presentation-preview-slide-prompt-input"
          @submit="generateOutput"
        />
        <Button
          v-if="selectedSlideDynamic"
          text="Regenerate"
          icon="ai"
          size="xs"
          :loading="loading"
          @click="generateOutput"
        />
        <Button
          v-if="selectedSlide && selectedSlide.prompt"
          text="Reset"
          size="xs"
          type="white"
          :loading="loading"
          @click="resetOutput"
        />
      </div>
    </div>
    <div class="presentation-preview-carousel">
      <PresentationOutputCarousel
        ref="outputcarousel"
        :slides="slides"
        :output="output"
        :selected-idx="selectedSlideIdx"
        :loading="loading"
        :slide-regenerating="slideRegenerating"
        :remove-loading="removeLoading"
        @select="selectSlide"
        @add="startAddSlides"
        @remove="removeSlide"
        @next="selectNext"
        @previous="selectPrevious"
      />
    </div>
    <div class="presentation-preview-pills">
      <PresentationOutputPills
        :slides="slides"
        :selected-idx="selectedSlideIdx"
        :loading="loading"
        @select="selectSlide"
      />
    </div>
    <PresentationOutputInsert
      v-if="addSlideModal > -1"
      :visible="addSlideModal > -1"
      :index="addSlideModal"
      :meeting="meeting"
      :output="output"
      :show-insert="showInsert"
      @close="() => (addSlideModal = -1)"
      @insert="finishAddSlides"
    />
  </div>
</template>

<script>
import PresentationOutputCarousel from './presentation/PresentationOutputCarousel.vue'
import PresentationOutputInsert from './presentation/PresentationOutputInsert.vue'
import PresentationOutputPills from './presentation/PresentationOutputPills.vue'
import TextInput from '@c/library/TextInput.vue'
import Button from '@c/library/Button.vue'

import { removeMeetingOutputSlide } from '@/services/meetingService'

export default {
  name: 'PresentationOutputPreview',
  components: {
    PresentationOutputCarousel,
    PresentationOutputInsert,
    PresentationOutputPills,
    TextInput,
    Button
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    output: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    slideRegenerating: {
      type: Number,
      default: -1
    },
    hideRegenerate: {
      type: Boolean,
      default: false
    },
    showInsert: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selectedSlideIdx: -1,
    prompt: '',
    addSlideModal: -1,
    removeLoading: -1
  }),
  computed: {
    slides() {
      return this.output?.presentation?.pages || []
    },
    showContent() {
      return (
        !this.loading &&
        this.selectedSlide &&
        this.slideRegenerating !== this.selectedSlideIdx
      )
    },
    selectedSlide() {
      return this.selectedSlideIdx > -1
        ? this.slides[this.selectedSlideIdx]
        : undefined
    },
    selectedSlideDynamic() {
      return this.selectedSlide?.dynamic || false
    },
    promptPlaceholder() {
      return this.slideRegenerating !== -1
        ? 'Regenerating slide...'
        : 'Optional instruction, try: "Make this sound easier" or "Put more emphasis on X"'
    }
  },
  watch: {
    loading(val) {
      if (!val) this.selectSlide(0)
    }
  },
  mounted() {
    if (this.selectedSlideIdx === -1 && this.slides.length) this.selectSlide(0)
    document.addEventListener('keydown', this.handleKeyPress)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyPress)
  },
  methods: {
    selectSlide(idx) {
      this.selectedSlideIdx = idx
      this.prompt = this.selectedSlide?.prompt || ''
      this.$refs.outputcarousel.scrollToIndex(idx)
    },
    resetOutput() {
      this.prompt = ''
      this.generateOutput()
    },
    generateOutput() {
      this.$emit('regenerate', this.prompt, this.selectedSlideIdx)
    },
    handleKeyPress(e) {
      const validKeys = ['ArrowLeft', 'ArrowRight']
      if (!validKeys.includes(e.key)) return
      e.preventDefault()
      if (e.key === 'ArrowRight') this.selectNext()
      else this.selectPrevious()
    },
    selectNext() {
      if (this.selectedSlideIdx + 1 < this.slides.length) {
        this.selectSlide(this.selectedSlideIdx + 1)
      }
    },
    selectPrevious() {
      if (this.selectedSlideIdx - 1 >= 0) {
        this.selectSlide(this.selectedSlideIdx - 1)
      }
    },
    startAddSlides(idx) {
      this.addSlideModal = idx
    },
    async removeSlide(idx) {
      try {
        this.removeLoading = idx
        const res = await removeMeetingOutputSlide({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid,
          output_id: this.output.uuid,
          slide_index: idx
        })
        this.$emit('edit', res)
      } catch (e) {
        this.$console.debug('Error while removing slide', e)
      } finally {
        this.removeLoading = -1
      }
    },
    finishAddSlides(slides) {
      this.addSlideModal = -1
      this.$emit('edit', slides)
    }
  }
}
</script>

<style lang="scss" scoped>
.presentation-preview {
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  background: #f9f9fa;
  height: 100%;
  max-height: 100%;

  &-slide {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;
    padding: 2.5rem 2.5rem 0;
    height: calc(75% - 2rem);
    position: relative;

    &-preview {
      height: calc(90% - 1rem);
      display: flex;
      align-items: center;
      justify-content: center;

      &.full-height {
        height: 100%;
      }

      &-img {
        max-height: 100%;
        max-width: 100%;

        &-wrapper {
          height: 100%;
          width: 100%;
          position: relative;
        }
      }

      &-regen {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }
    }

    ::v-deep & .b-skeleton {
      width: unset;
      height: 100%;
      max-height: 100%;
      max-width: 100%;

      &-item {
        height: 100%;
        aspect-ratio: 16 / 9;
      }
    }

    &-prompt {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      border-radius: 8px;
      border: 1px solid rgba(#000, 0.08);
      background: white;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      user-select: none;

      &.visible {
        opacity: 1;
        pointer-events: auto;
      }

      &-input {
        flex: 1;
      }
    }
  }

  &-carousel {
    height: calc(20% - 2rem);
  }

  &-pills {
    height: 5%;
    padding: 0 1.75rem 1.75rem;
  }
}
</style>
