var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "output-carousel-wrapper" }, [
    _c(
      "div",
      {
        ref: "outputcarousel",
        staticClass: "output-carousel",
        class: { "has-add": _vm.showInsert }
      },
      [
        !_vm.loading
          ? _c(
              "div",
              {
                staticClass: "output-carousel-slide-add",
                on: {
                  click: function() {
                    return _vm.$emit("add", 0)
                  }
                }
              },
              [
                _c("div", { staticClass: "output-carousel-slide-add-line" }),
                _c("img", {
                  staticClass: "output-carousel-slide-add-plus",
                  attrs: {
                    src: require("@/assets/icons/plus-medium.svg"),
                    alt: ""
                  }
                }),
                _c("div", { staticClass: "output-carousel-slide-add-line" })
              ]
            )
          : _vm._e(),
        _vm._l(_vm.loading ? [] : _vm.slides, function(slide, idx) {
          return _c(
            "div",
            {
              key: "output-carousel-slide-" + idx,
              staticClass: "output-carousel-slide-wrapper"
            },
            [
              _c(
                "div",
                {
                  staticClass: "output-carousel-slide",
                  class: {
                    selected: _vm.selectedIdx === idx,
                    inserted: slide.slide_type === "template_block_slide",
                    merged: slide.slide_type === "merged_slide"
                  },
                  attrs: {
                    id: "output-carousel-slide-" + _vm.output.uuid + "-" + idx
                  },
                  on: {
                    click: function() {
                      return _vm.$emit("select", idx)
                    }
                  }
                },
                [
                  _vm.slideRegenerating !== idx
                    ? _c("img", {
                        staticClass: "output-carousel-slide-preview",
                        attrs: { src: slide.preview, alt: "" }
                      })
                    : _c("b-skeleton", {
                        staticClass: "output-carousel-slide-preview"
                      }),
                  _c(
                    "div",
                    { staticClass: "output-carousel-slide-dynamic-wrapper" },
                    [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            type: "is-dark",
                            position: "is-top",
                            label: "Remove slide",
                            "append-to-body": true
                          }
                        },
                        [
                          slide.slide_type === "merged_slide" &&
                          !(
                            _vm.removeLoading !== -1 &&
                            _vm.removeLoading !== idx
                          )
                            ? _c("Button", {
                                staticClass: "output-carousel-slide-remove",
                                class: { visible: _vm.removeLoading === idx },
                                attrs: {
                                  icon: "bin",
                                  type: "white",
                                  loading: _vm.removeLoading === idx
                                },
                                on: {
                                  click: function() {
                                    return _vm.removeInsertedSlide(idx)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      slide.dynamic || slide.slide_type !== "template_slide"
                        ? _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.slideTagLabel(slide),
                                type: "is-dark",
                                position: "is-top",
                                "append-to-body": true
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "output-carousel-slide-dynamic",
                                  class: {
                                    inserted:
                                      slide.slide_type ===
                                      "template_block_slide",
                                    merged: slide.slide_type === "merged_slide"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "output-carousel-slide-dynamic-icon",
                                    attrs: {
                                      src: require("@/assets/icons/" +
                                        _vm.slideTagIcon[slide.slide_type] +
                                        ".svg"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "output-carousel-slide-add-wrapper" },
                [
                  _vm.showInsert
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            type: "is-dark",
                            position: "is-right",
                            label: "Add portfolio content",
                            "append-to-body": true
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "output-carousel-slide-add",
                              on: {
                                click: function() {
                                  return _vm.$emit("add", idx + 1)
                                }
                              }
                            },
                            [
                              _c("div", {
                                staticClass: "output-carousel-slide-add-line"
                              }),
                              _c("img", {
                                staticClass: "output-carousel-slide-add-plus",
                                attrs: {
                                  src: require("@/assets/icons/plus-medium.svg"),
                                  alt: ""
                                }
                              }),
                              _c("div", {
                                staticClass: "output-carousel-slide-add-line"
                              })
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        }),
        _vm._l(
          _vm.loading || !(_vm.slides && _vm.slides.length)
            ? [1, 2, 3, 4, 5, 6, 7]
            : [],
          function(s) {
            return _c("b-skeleton", {
              key: "output-carousel-slide-" + s,
              staticClass: "output-carousel-slide-preview"
            })
          }
        )
      ],
      2
    ),
    !_vm.leftArrowDisabled
      ? _c("img", {
          staticClass: "output-carousel-arrow left",
          attrs: {
            src: require("@/assets/icons/carousel-arrow-right.svg"),
            alt: ""
          },
          on: {
            click: function() {
              return _vm.gotoSlide(-1)
            }
          }
        })
      : _vm._e(),
    !_vm.rightArrowDisabled
      ? _c("img", {
          staticClass: "output-carousel-arrow right",
          attrs: {
            src: require("@/assets/icons/carousel-arrow-right.svg"),
            alt: ""
          },
          on: {
            click: function() {
              return _vm.gotoSlide(1)
            }
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }