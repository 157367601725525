var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion" },
    _vm._l(_vm.items, function(item) {
      return _c(
        "div",
        {
          key: "accordion-" + item.id,
          staticClass: "accordion-item",
          class: { bordered: _vm.bordered }
        },
        [
          _c(
            "div",
            {
              staticClass: "accordion-item-header",
              class: {
                opened:
                  _vm.openedItem === item.id ||
                  _vm.openedItems.includes(item.id)
              },
              style: _vm.headerStyle,
              on: {
                click: function() {
                  return _vm.toggleOpen(item)
                }
              }
            },
            [
              _c("img", {
                staticClass: "accordion-item-header-icon",
                attrs: {
                  src: require("@/assets/icons/chevron-down-medium.svg"),
                  alt: ""
                }
              }),
              _vm._t(
                "header",
                function() {
                  return [_vm._v(" " + _vm._s(item.name) + " ")]
                },
                { item: item }
              ),
              _c(
                "div",
                { staticClass: "accordion-item-header-buttons" },
                [_vm._t("buttons", null, { item: item })],
                2
              )
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "accordion-item-content",
              class: {
                opened:
                  _vm.openedItem === item.id ||
                  _vm.openedItems.includes(item.id)
              }
            },
            [_vm._t("content", null, { item: item })],
            2
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }