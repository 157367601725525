<template>
  <div class="output-carousel-wrapper">
    <div
      ref="outputcarousel"
      class="output-carousel"
      :class="{ 'has-add': showInsert }"
    >
      <div
        v-if="!loading"
        class="output-carousel-slide-add"
        @click="() => $emit('add', 0)"
      >
        <div class="output-carousel-slide-add-line"></div>
        <img
          src="@/assets/icons/plus-medium.svg"
          alt=""
          class="output-carousel-slide-add-plus"
        />
        <div class="output-carousel-slide-add-line"></div>
      </div>
      <div
        v-for="(slide, idx) in loading ? [] : slides"
        :key="`output-carousel-slide-${idx}`"
        class="output-carousel-slide-wrapper"
      >
        <div
          :id="`output-carousel-slide-${output.uuid}-${idx}`"
          class="output-carousel-slide"
          :class="{
            selected: selectedIdx === idx,
            inserted: slide.slide_type === 'template_block_slide',
            merged: slide.slide_type === 'merged_slide'
          }"
          @click="() => $emit('select', idx)"
        >
          <img
            v-if="slideRegenerating !== idx"
            :src="slide.preview"
            alt=""
            class="output-carousel-slide-preview"
          />
          <b-skeleton v-else class="output-carousel-slide-preview" />
          <div class="output-carousel-slide-dynamic-wrapper">
            <b-tooltip
              type="is-dark"
              position="is-top"
              label="Remove slide"
              :append-to-body="true"
            >
              <Button
                v-if="
                  slide.slide_type === 'merged_slide' &&
                    !(removeLoading !== -1 && removeLoading !== idx)
                "
                icon="bin"
                type="white"
                class="output-carousel-slide-remove"
                :class="{ visible: removeLoading === idx }"
                :loading="removeLoading === idx"
                @click="() => removeInsertedSlide(idx)"
              />
            </b-tooltip>
            <b-tooltip
              v-if="slide.dynamic || slide.slide_type !== 'template_slide'"
              :label="slideTagLabel(slide)"
              type="is-dark"
              position="is-top"
              :append-to-body="true"
            >
              <div
                class="output-carousel-slide-dynamic"
                :class="{
                  inserted: slide.slide_type === 'template_block_slide',
                  merged: slide.slide_type === 'merged_slide'
                }"
              >
                <img
                  :src="
                    require(`@/assets/icons/${
                      slideTagIcon[slide.slide_type]
                    }.svg`)
                  "
                  alt=""
                  class="output-carousel-slide-dynamic-icon"
                />
              </div>
            </b-tooltip>
          </div>
        </div>
        <div class="output-carousel-slide-add-wrapper">
          <b-tooltip
            v-if="showInsert"
            type="is-dark"
            position="is-right"
            label="Add portfolio content"
            :append-to-body="true"
          >
            <div
              class="output-carousel-slide-add"
              @click="() => $emit('add', idx + 1)"
            >
              <div class="output-carousel-slide-add-line"></div>
              <img
                src="@/assets/icons/plus-medium.svg"
                alt=""
                class="output-carousel-slide-add-plus"
              />
              <div class="output-carousel-slide-add-line"></div>
            </div>
          </b-tooltip>
        </div>
      </div>
      <b-skeleton
        v-for="s in loading || !(slides && slides.length)
          ? [1, 2, 3, 4, 5, 6, 7]
          : []"
        :key="`output-carousel-slide-${s}`"
        class="output-carousel-slide-preview"
      />
    </div>
    <img
      v-if="!leftArrowDisabled"
      src="@/assets/icons/carousel-arrow-right.svg"
      alt=""
      class="output-carousel-arrow left"
      @click="() => gotoSlide(-1)"
    />
    <img
      v-if="!rightArrowDisabled"
      src="@/assets/icons/carousel-arrow-right.svg"
      alt=""
      class="output-carousel-arrow right"
      @click="() => gotoSlide(1)"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'

export default {
  name: 'PresentationOutputCarousel',
  components: {
    Button
  },
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    output: {
      type: Object,
      default: () => ({})
    },
    selectedIdx: {
      type: Number,
      default: -1
    },
    loading: {
      type: Boolean,
      default: false
    },
    slideRegenerating: {
      type: Number,
      default: -1
    },
    showInsert: {
      type: Boolean,
      default: true
    },
    removeLoading: {
      type: Number,
      default: -1
    }
  },
  computed: {
    slideTagIcon() {
      return {
        template_slide: 'ai',
        template_block_slide: 'compass',
        merged_slide: 'user'
      }
    },
    leftArrowDisabled() {
      return this.selectedIdx === 0
    },
    rightArrowDisabled() {
      return this.selectedIdx === this.slides.length - 1
    }
  },
  methods: {
    gotoSlide(direction) {
      this.$emit(direction > 0 ? 'next' : 'previous')
    },
    scrollToIndex(idx) {
      const el = this.$refs.outputcarousel
      const slide = document.getElementById(
        `output-carousel-slide-${this.output.uuid}-${idx}`
      )
      if (!el || !slide) return
      el.scrollTo({
        left: slide.offsetLeft - el.offsetWidth / 2 + slide.offsetWidth / 2,
        behavior: 'smooth'
      })
    },
    slideTagLabel(slide) {
      return {
        template_slide: 'AI enhanced slide',
        template_block_slide: `Inserted from ${slide.ori_name}`,
        merged_slide: 'Added by you'
      }[slide.slide_type]
    },
    removeInsertedSlide(idx) {
      this.$emit('remove', idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.output-carousel {
  display: flex;
  flex-flow: column wrap;
  overflow-x: auto;
  height: 100%;
  max-height: 100%;
  width: fit-content;
  margin: 0 auto;
  padding: 0 1.5rem;

  --output-carousel-border-radius: 8px;
  --output-carousel-inner-padding: 0.25rem;

  &::-webkit-scrollbar {
    display: none;
  }

  &.has-add {
    gap: 0.25rem;
  }

  &:not(.has-add) {
    gap: 1.5rem;
  }

  &:hover {
    & .output-carousel-slide-add {
      opacity: 1;
    }
  }

  &-wrapper {
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  &-slide {
    position: relative;
    height: 100%;
    width: fit-content;
    cursor: pointer;
    border-radius: var(--output-carousel-border-radius);
    padding: var(--output-carousel-inner-padding);
    border: 2px solid transparent;
    transition: border-color 0.2s;

    &:hover {
      border-color: rgba($primary, 0.3);

      & .output-carousel-slide-remove {
        opacity: 1;
      }
    }

    &.selected {
      border-color: $primary;
    }

    &.inserted {
      &:hover {
        border-color: rgba(#8937df, 0.3);
      }

      &.selected {
        border-color: #8937df;
      }
    }

    &.merged {
      &:hover {
        border-color: rgba(#f29203, 0.3);
      }

      &.selected {
        border-color: #f29203;
      }
    }

    &-wrapper {
      display: contents;
    }

    &-preview {
      height: 100%;
      border-radius: calc(
        var(--output-carousel-border-radius) -
          var(--output-carousel-inner-padding)
      );
    }

    &-remove {
      opacity: 0;
      transition: opacity 0.2s;

      &.visible {
        opacity: 1;
      }
    }

    &-dynamic {
      background: $primary;
      border-radius: 0
        calc(
          var(--output-carousel-border-radius) -
            var(--output-carousel-inner-padding)
        )
        0 12px;
      padding: 0.35rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.inserted {
        background: #8937df;
      }

      &.merged {
        background: #f29203;
      }

      &-wrapper {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
      }

      &-icon {
        width: 1.2rem;
        height: 1.2rem;
        filter: brightness(0) invert(1);
      }
    }

    &-add {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 0;
      cursor: pointer;
      border-radius: 999rem;
      opacity: 0;
      height: 100%;
      transition: background 0.2s ease, opacity 0.2s ease;

      &:hover {
        background: rgba(#000, 0.04);
      }

      &-line {
        width: 1px;
        flex: 1;
        background: #dddfe2;
        transition: background 0.2s ease;
      }

      &-plus {
        height: 1.2rem;
      }

      &-wrapper {
        height: 100%;
        &::v-deep .b-tooltip {
          height: 100%;
        }
      }
    }
  }

  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.2s, transform 0.2s;
    border-radius: 999rem;
    cursor: pointer;

    &.left {
      left: 0.5rem;
      transform: rotate(180deg) translateY(50%);

      &:hover {
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
      }
    }

    &.right {
      right: 0.5rem;

      &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
}

::v-deep .b-skeleton {
  margin: 0;
  border-radius: 8px;
  height: 100%;
  flex: unset !important;
  width: fit-content;

  &-item {
    height: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 8px;
  }
}
</style>
